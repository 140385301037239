import React from 'react';

import className from './Remittance.module.css';
import { Link, NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import axios from 'axios';
import Pop from './Pop';

function Surprise() {
 return (
    <>
    <h1 className="p-5 justify-content-center">Surprise Coming Soon</h1>
    </>
  );
}

export default Surprise;
